<i18n>
{
  "de": {
    "buttonLabel": "Bearbeiten"
  }
}
</i18n>

<template>
  <ListButton
    :img-src="viewIcon ? '/icons/chevron-right.svg' : '/icons/edit.svg'"
    :label="$t('buttonLabel')"
    :disabled="disabled"
    @click="onClick()"
  />
</template>

<script>
import ListButton from '@/components/shared/lists/ListButton.vue'

export default {
  components: {
    ListButton,
  },

  props: {
    viewIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
